/**
 * Utilities for traversing an organization tree with parent and
 * child organizations using a filter expression.
 * @module lib/utils/organization
 */
const orderBy = require('lodash/orderBy');
const { DateTime } = require('luxon');
require('../types')

/**
 * Returns the organization or a parent of the organization that matches the
 * {@link compareFn} or {@link proceedWithParentsFn}.
 * Use to traverse the hierarchy up to top until an organization that matches
 * the compare fn is found (e.g. organization that has a certain property).
 * @param {Organization} organization
 * @param {compareFnHandler} compareFn
 * @param {func} proceedWithParentsFn
 * @returns {Organization|null}
 */
function findParentOrganization(
  organization,
  compareFn = findParentOrganizationDefaultFilter,
  proceedWithParentsFn = proceedWithParentsDefaultFilter,
) {
  if (compareFn(organization)) {
    return organization;
  }

  if (Array.isArray(organization?.parent_organizations)
    && proceedWithParentsFn(organization)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const parentOrganization of organization.parent_organizations) {
      const result = findParentOrganization(parentOrganization, compareFn);

      if (result) {
        return result;
      }
    }
  }

  return null;
}

/**
 * Get a chain of organizations (parent organizations) up to the top filtered
 * by {@link compareFn} and {@link proceedWithParentsFn}.
 * @param {Organization} organization
 * @param {compareFnHandler} compareFn
 * @param {func} proceedWithParentsFn
 * @param {boolean} toBottom
 * @returns {Organization[]}
 */
function getOrganizationChainToTop(
  organization,
  compareFn = findParentOrganizationDefaultFilter,
  proceedWithParentsFn = proceedWithParentsDefaultFilter,
  toBottom = false,
) {
  let result = [];

  if (Array.isArray(organization?.parent_organizations)
    && proceedWithParentsFn(organization)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const parentOrganization of organization.parent_organizations) {
      result = getOrganizationChainToTop(parentOrganization, compareFn, proceedWithParentsFn);
    }
  }

  if (compareFn(organization)) {
    result.push(organization);
  }

  if (toBottom && Array.isArray(organization.organizations)) {
    result.push(...getOrganizationChainToBottom(organization, compareFn, true));
  }

  return result;
}

/**
 * Get a chain of organizations (parent organizations) down to the bottom filtered
 * by {@link compareFn}.
 * @param {Organization} organization
 * @param {compareFnHandler} compareFn
 * @param {boolean} onlyChildren
 * @returns {Organization[]}
 */
function getOrganizationChainToBottom(
  organization,
  compareFn = findParentOrganizationDefaultFilter,
  onlyChildren = false,
) {
  const result = [];

  if (Array.isArray(organization?.organizations)) {
    result.push(
      ...organization.organizations
        .flatMap((childOrganization) => getOrganizationChainToBottom(childOrganization, compareFn)),
    );
  }

  if (!onlyChildren && compareFn(organization)) {
    result.push(organization);
  }

  return result;
}

/**
 * @callback compareFnHandler
 * @param {Organization} org
 * @returns {boolean}
 */

/**
 * Default filter function for {@link findParentOrganization},
 * {@link getOrganizationChainToTop} & {@link getOrganizationChainToBottom}.
 * @param {Organization} org
 * @returns {boolean}
 */
function findParentOrganizationDefaultFilter(org) {
  return Boolean(org);
}

/**
 * Default filter function for {@link findParentOrganization},
 * {@link getOrganizationChainToTop} & {@link getOrganizationChainToBottom}.
 * @param {Organization} org
 * @returns {boolean}
 */
function proceedWithParentsDefaultFilter(org) {
  return !org?.isParentOrgHidden;
}

/**
 * Accepts an {@link Organization} as input and checks the history entries for an entry that
 * matches the project date range (start to end) and replaces the abbreviation with the entry from
 * the history so, that the organization is shown with the name that it had during this period.
 * @param {Project} project
 * @param {Organization} org
 * @returns {Organization|null}
 */
function checkOrgHistory(project, org) {
  if (!org) {
    return null;
  }

  const { projectEnd } = project;
  const { isHistoryVisible } = org;
  const historyItems = orderBy(
    org.organization_histories ?? [],
    ['date'],
    ['asc'],
  );

  if (!projectEnd || !isHistoryVisible) {
    return org;
  }

  const end = DateTime.fromISO(projectEnd).startOf('day');

  // find history item with end date equal/after project end date
  const item = historyItems.find((historyItem) => {
    const renameDate = DateTime.fromISO(historyItem.date).startOf('day');
    return renameDate >= end;
  });

  if (item) {
    return {
      ...org,
      abbreviation_de: item.abbreviation_de,
      abbreviation_en: item.abbreviation_en,
    };
  }

  return org;
}

/**
 * Calculates the organization classification based on the SME definition by the EU.
 * {@link https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:32003H0361}
 * @param {Organization} o
 * @returns {string|null}
 */
function getOrganizationCategory(o) {
  if (![
    'company',
    'location',
    'department',
  ].includes(o.organizationType)) {
    return null;
  }

  const compare = (org) => org && org.publicationState === 'published'
    && !org.isHiddenInCompanyDatabase
    && org.numOfEmployees && (
    org.turnoverPerYear || org.balanceSheetTotal
  );
  const org = findParentOrganization(o, compare);

  if (!org) {
    return null;
  }

  const {
    numOfEmployees: empl,
    turnoverPerYear: turn,
    balanceSheetTotal: blnc,
  } = org;

  if (empl === 'upTo9' && (
    turn === 'upTo2Million'
    || blnc === 'upTo2Million')
  ) {
    return 'smallest';
  }

  if (
    ((empl === 'upTo9' || empl === 'upTo49') && (turn === 'upTo10Million' || blnc === 'upTo10Million'))
    || (empl === 'upTo49' && (
      turn === 'upTo2Million' || blnc === 'upTo2Million'
      || turn === 'upTo10Million' || blnc === 'upTo10Million'))
  ) {
    return 'small';
  }

  if (
    (
      (empl === 'upTo9' || empl === 'upTo49' || empl === 'upTo249')
      && (turn === 'upTo50Million' || blnc === 'upTo43Million'))
    || (empl === 'upTo249' && (
      turn === 'upTo2Million' || blnc === 'upTo2Million'
      || turn === 'upTo10Million' || blnc === 'upTo10Million'
      || turn === 'upTo50Million' || blnc === 'upTo43Million'))
  ) {
    return 'medium';
  }

  if (empl === 'over250' || turn === 'over50Million' || blnc === 'over43Million') {
    return 'large';
  }

  return null;
}

module.exports = {
  findParentOrganization,
  getOrganizationChainToTop,
  getOrganizationChainToBottom,
  checkOrgHistory,
  getOrganizationCategory,
  proceedWithParentsDefaultFilter,
};
