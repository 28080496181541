/* eslint-disable no-param-reassign, no-unused-vars */
/**
 * Utilities for parsing and formatting numbers including currency values.
 * @module common/utils/number-utils
 */
import { Decimal } from 'decimal.js';
import {
  string,
  StringSchema,
} from 'yup';

/**
 * Money conversion factor to store money values as integer values in database.
 * @type {Decimal}
 */
const MONEY_CONVERT_FACTOR = new Decimal(100);

/**
 * Converts a string to a number multiplied by {@link MONEY_CONVERT_FACTOR}
 * @param value
 * @returns {number|null}
 */
export function convertInputToMoney(value) {
  if (!value) return value;

  value = value.replaceAll('.', '').replace(',', '.');

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  return Math.trunc(d.mul(MONEY_CONVERT_FACTOR).toNumber());
}

/**
 * Converts a number to a string divided by {@link MONEY_CONVERT_FACTOR}
 * @param value
 * @returns {string|null}
 */
export function convertMoneyToInput(value) {
  if (!value) return value;

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  value = d.div(MONEY_CONVERT_FACTOR).toFixed(2);

  return value.replace('.', ',');
}

/**
 * Create a yup schema for validating if a string is a decimal value.
 * @returns {StringSchema}
 */
export function money() {
  return string().trim().matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(\.\d{3})*)|\d+)?(,\d{1,2})?$/);
}

/**
 * Renders a money value (integer) to a string with currency symbol.
 * Currency symbol is EUR.
 * Currency locale is de-De.
 * @param value
 * @param options
 * @returns {string|null}
 */
export function renderMoneyValue(value, options = {}) {
  if (!value) return value;

  const d = new Decimal(value);

  if (d.isNaN()) return value;

  return d.div(MONEY_CONVERT_FACTOR)
    .toNumber()
    .toLocaleString('de-DE', {
      ...options,
      style: 'currency',
      currency: 'EUR',
    });
}
