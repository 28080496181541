/**
 * File utils
 * @module common/utils/file
 */

/**
 * Get file extension from filename.
 * @param filename
 * @returns {string | undefined}
 */
export function getFileType(filename) {
  return filename?.split('.').pop()?.toUpperCase();
}
