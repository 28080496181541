/**
 * Logging utils
 * @module common/utils/log
 */
import * as Sentry from '@sentry/nextjs';

/**
 * Capture an exception and log it to the console & Sentry
 * @param err
 */
export function captureException(err) {
  console.error(err);
  Sentry.captureException(err);
}
