/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';
import {
  findParentOrganization,
  getOrganizationChainToTop,
} from 'shared/lib/utils/organization';
import {
  Box,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import TagMap from '@components/tags/TagMap';
import ResponsiveImage from '@components/ResponsiveImage';
import Link from './Link';

export default function Organization(props) {
  const {
    organization,
    noLogo,
    secondary,
    tagItems,
  } = props;
  const { t, i18n } = useTranslation();

  const chain = getOrganizationChainToTop(organization);
  const liquidatedOrg = findParentOrganization(
    organization,
    (o) => o && o.attributes.liquidationState === 'liquidated',
  );
  const isLiquidated = Boolean(liquidatedOrg);
  const addressZipCityOrg = findParentOrganization(
    organization,
    (o) => o && o.attributes.publicationState === 'published'
      && o.attributes.addressZipCode
      && o.attributes.addressCity,
  );
  const websiteOrg = findParentOrganization(
    organization,
    (o) => o && o.attributes.publicationState === 'published'
      && o.attributes[`website_${i18n.language}`],
  );
  const organizationWithSectorTags = findParentOrganization(
    organization,
    (o) => o && o.attributes.publicationState === 'published'
      && o.attributes.tag_items.data.length > 0,
  );
  const selectedTags = organizationWithSectorTags
    ? organization.attributes.tag_items.data.map((x) => x.attributes.tag_item.data.id)
    : [];

  return (
    <Grid
      container
      spacing={3}
    >
      <Grid item md={9} sm={8} xs={12}>
        <Box>
          {chain.map((org, index) => (
            <Typography
              key={org.id}
              sx={{ fontWeight: index === chain.length - 1 ? (secondary ? 'semibold' : 'bold') : null }}
              variant="subtitle2"
            >
              {`${org.attributes[`name_${i18n.language}`]}
              (${org.attributes[`abbreviation_${i18n.language}`]})`}
            </Typography>
          ))}
        </Box>
        {isLiquidated && (
          <Box mb={1} ml={-1}>
            <Chip
              color="warning"
              label={`liquidiert am: ${liquidatedOrg.attributes.liquidationDate
                ? DateTime.fromISO(liquidatedOrg.attributes.liquidationDate)
                  .setLocale(i18n.language)
                  .toFormat(t('shared.format.date.short'))
                : 'keine Angabe'}`}
              size="small"
            />
          </Box>
        )}
        {addressZipCityOrg && (
          <Typography
            color={isLiquidated ? 'text.disabled' : null}
            variant="body2"
          >
            {`${addressZipCityOrg.attributes.addressStreet ?? ''} ${addressZipCityOrg.attributes.addressStreetNumber ?? ''}`}
          </Typography>
        )}
        {addressZipCityOrg && addressZipCityOrg.attributes.addressSuffix1 && (
          <Typography
            color={isLiquidated ? 'text.disabled' : null}
            variant="body2"
          >
            {addressZipCityOrg.attributes.addressSuffix1}
          </Typography>
        )}
        {addressZipCityOrg && addressZipCityOrg.attributes.addressSuffix2 && (
          <Typography
            color={isLiquidated ? 'text.disabled' : null}
            variant="body2"
          >
            {addressZipCityOrg.attributes.addressSuffix2}
          </Typography>
        )}
        {addressZipCityOrg && (
          <Typography
            color={isLiquidated ? 'text.disabled' : null}
            variant="body2"
          >
            {`${addressZipCityOrg.attributes.addressZipCode ?? ''} ${addressZipCityOrg.attributes.addressCity ?? ''}`}
          </Typography>
        )}
        {addressZipCityOrg?.attributes.addressCountry && (
          <Typography
            color={isLiquidated ? 'text.disabled' : null}
            variant="body2"
          >
            {addressZipCityOrg.attributes.addressCountry}
          </Typography>
        )}
        {websiteOrg && (
          <Link
            href={websiteOrg.attributes[`website_${i18n.language}`]}
            name="Zum Internetauftritt"
          />
        )}
        {tagItems && (
          <Box mt={1}>
            {organizationWithSectorTags ? (
              <TagMap
                entityTags={selectedTags}
                tagItems={tagItems}
                titleContainerProps={{
                  mb: 0,
                }}
                titleTypographyProps={{
                  variant: 'caption',
                }}
              />
            ) : (
              <Typography
                color="text.disabled"
                variant="body2"
              >
                nicht eingeordnet
              </Typography>
            )}
          </Box>
        )}
      </Grid>
      {!noLogo && organization.attributes.logo_default_de?.data && (
        <Grid item md={3} sm={4} xs={12}>
          <ResponsiveImage
            src={
              organization.attributes.logo_default_de.data.attributes.formats?.small?.url
              ?? organization.attributes.logo_default_de.data.attributes.formats?.medium?.url
              ?? organization.attributes.logo_default_de.data.attributes.formats?.large?.url
              ?? organization.attributes.logo_default_de.data.attributes.url
            }
          />
        </Grid>
      )}
    </Grid>
  );
}
