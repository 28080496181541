import {
  checkOrgHistory,
  findParentOrganization,
  getOrganizationChainToTop,
} from 'shared/lib/utils/organization';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

export * from './general-project-schema';

export function getGeneralProjectInfos(data, context) {
  const { id } = data;
  const usedOrgs = [];

  return {
    id,
    // General stuff
    isSubProject: data.attributes.projectType === 'subProject',
    isShowNotesForSubProjects: data.attributes.isShowNotesForSubProjects,
    title: getProjectTitle(data, context),
    name: getProjectName(data, context),
    type: getProjectType(data, context),
    projectStart: data.attributes.projectStart,
    projectEnd: data.attributes.projectEnd,
    fundingAmount: data.attributes.fundingAmount,
    projectVolume: data.attributes.projectVolume,
    projectLogos: getProjectLogos(data, context),
    // Organizations
    projectManagerOrg: getProjectManagerOrg(data, context, usedOrgs),
    projectLocation: getProjectLocation(data, context),
    projectExecutingOrgs: getExecutingOrgs(data, context, usedOrgs),
    projectFundingOrgs: getFundingOrgs(data, context),
  };
}

function getProjectTitle(p, { t, i18n }) {
  let title = p.attributes.abbreviation;

  if (p.attributes.projectType === 'subProject') {
    title = p.attributes.parent_project?.data?.attributes.abbreviation || '[Verbundprojekt]';
  }

  return title;
}

function getProjectName(p) {
  let name = p.attributes.name_de;

  if (p.attributes.projectType === 'subProject') {
    name = p.attributes.parent_project?.data?.attributes.name_de || '[fehlendes Thema]';
  }

  return name;
}

function getProjectType(p, { t, i18n }) {
  let label1 = '';
  let label2 = '';

  if (!p.attributes.projectType) {
    return null;
  }

  if (i18n.exists(`enums.projectType.${p.attributes.projectType}`)) {
    label1 += t(`enums.projectType.${p.attributes.projectType}`);
  } else {
    label1 += p.attributes.projectType;
  }

  if (p.attributes.projectType === 'subProject') {
    label1 += ` ${p.attributes.abbreviation}` || '[fehlende Nummer]';

    if (!p.attributes.parent_project?.data?.attributes.isShowNotesForSubProjects) {
      label2 = ` von ${p.attributes.parent_project?.data?.attributes.projects.data.length ?? 0}`;
    }
  }

  return {
    value: p.attributes.projectType,
    label1,
    label2,
  };
}

function getProjectLogos(p) {
  const logos = [];
  const isSubProject = p.attributes.projectType === 'subProject';
  const parentProject = isSubProject ? p.attributes.parent_project?.data?.attributes : null;

  if (isSubProject && parentProject?.logo_default?.data) {
    logos.push(parentProject.logo_default.data.attributes.formats.small
      ?? parentProject.logo_default.data.attributes.formats.medium
      ?? parentProject.logo_default.data.attributes.formats.large
      ?? parentProject.logo_default.data.attributes);
  }

  if (p.attributes.logo_default?.data) {
    logos.push(p.attributes.logo_default.data.attributes.formats.small
      ?? p.attributes.logo_default.data.attributes.formats.medium
      ?? p.attributes.logo_default.data.attributes.formats.large
      ?? p.attributes.logo_default.data.attributes);
  }

  return logos;
}

function getProjectManagerOrg(p, { t, i18n }, usedOrgs) {
  let chains = [];
  const compare = (org) => org && org.attributes.publicationState === 'published';

  if (p.attributes.projectType === 'jointProject') {
    chains = [
      p,
      ...p.attributes.projects.data,
    ].map(
      (subProject) => getOrganizationChainToTop(
        subProject.attributes.project_manager_org?.data,
        compare,
      ).map((org) => checkOrgHistory(subProject, org)),
    );
  } else {
    const orgs = getOrganizationChainToTop(p.attributes.project_manager_org?.data, compare);

    if (orgs.length > 0) {
      chains.push(orgs.map((org) => checkOrgHistory(p, org)));
    }
  }

  chains = chains.filter((chain) => chain.length > 0);
  chains = uniqBy(chains, (chain) => chain.map((org) => org.id).join(''));
  const firstId = chains[0]?.[0]?.id;
  chains = chains.map((chain) => chain.map((org) => org.attributes[`abbreviation_${i18n.language}`] || `[${org.id}]`));

  // Do not sort the orgs here, this will give wrong results
  // chains = orderBy(chains, (chain) => chain[0], ['asc']);

  if (chains.length === 0) {
    return [['k. A.']];
  }

  usedOrgs.push(firstId);
  return [chains[0]];
}

function getProjectLocation(p, { t, i18n }) {
  const compare = (org) => org && org.attributes.publicationState === 'published'
    && org.attributes.addressZipCode
    && org.attributes.addressCity;
  const org = findParentOrganization(p.attributes.project_manager_org?.data, compare);

  if (!org) {
    return 'k. A.';
  }

  return `${org.attributes.addressZipCode} ${org.attributes.addressCity}`;
}

function getExecutingOrgs(project, { i18n }, usedOrgs) {
  let chains = [];
  const compare = (org) => org && org.attributes.publicationState === 'published';

  if (project.attributes.projectType === 'jointProject') {
    chains = project.attributes.projects.data.flatMap(
      (subProject) => [subProject.attributes.primary_executing_org?.data]
        .filter(Boolean)
        .map((org) => getOrganizationChainToTop(org, compare)
          .map((org2) => checkOrgHistory(project, org2))),
    );
  } else {
    chains = [project.attributes.primary_executing_org?.data]
      .filter(Boolean)
      .map((org) => getOrganizationChainToTop(org, compare)
        .map((org2) => checkOrgHistory(project, org2))) ?? [];
  }

  chains = chains.filter((chain) => chain.length > 0 && !usedOrgs.includes(chain[0].id));
  chains = uniqBy(chains, (chain) => chain.map((org) => org.id).join(''));
  chains = chains.map((chain) => chain.map((org) => org.attributes[`abbreviation_${i18n.language}`] || `[${org.id}]`));
  chains = orderBy(chains, (chain) => chain[0], ['asc']);

  if (chains.length === 0) {
    return [['k. A.']];
  }

  return chains;
}

function getFundingOrgs(project, { i18n }) {
  let orgs = [];
  const compare = (org) => org && org.attributes.organizationType === 'federalMinistry'
    && org.attributes.publicationState === 'published';

  if (project.attributes.projectType === 'jointProject') {
    orgs = project.attributes.projects.data.map(
      (subProject) => findParentOrganization(subProject.attributes.funding_org?.data, compare),
    )
      .filter(Boolean);
  } else {
    const org = findParentOrganization(project.attributes.funding_org?.data, compare);

    if (org) orgs.push(org);
  }

  orgs = uniq(orgs
    .map((org) => org.attributes[`abbreviation_${i18n.language}`] || `[${org.id}]`))
    .sort();

  if (orgs.length === 0) {
    return 'k. A.';
  }

  return orgs.join(', ');
}
